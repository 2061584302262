<template>
  <section>

    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'browse-campaigns' }">
              {{ resourceNamePlural }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              Create
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col
        md="12"
        class="mb-2 text-right"
      >
        <b-button
          variant="primary"
          :to="{ name: 'browse-campaigns' }"
        >
          <feather-icon
            icon="ListIcon"
            size="16"
            class="align-text-top"
          />  Manage {{ resourceNamePlural }}
        </b-button>
      </b-col>
    </b-row>

    <!-- companies -->
    <b-row v-if="adminAccess">
      <b-col md="12">
        <root-client-select
          class="mb-2"
          :load-company="selectedCompany"
          load-default
          @setCompany="setCompanyId"
          @setClient="setClientId"
        />
      </b-col>
    </b-row>

    <b-card class="p-1">
      <b-card-title class="pl-1 border-left-primary border-left-3">
        Add New {{ resourceName }}
      </b-card-title>
      <!-- form -->
      <validation-observer
        ref="resourceForm"
        #default="{invalid, dirty}"
      >
        <b-form @submit.prevent="createCampaign">
          <b-row>
            <!-- name -->
            <b-col cols="12">
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                  vid="name"
                >
                  <b-form-input
                    id="name"
                    v-model="campaignData.name"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Campaign Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- start date -->
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="Start Date"
                rules="required"
                vid="start_date"
              >
                <date-time-form
                  v-model="campaignData.start_date"
                  prefix="Start"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- end date -->
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="End Date"
                rules="required"
                vid="end_date"
              >
                <date-time-form
                  v-model="campaignData.end_date"
                  prefix="End"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- submit -->
            <b-col
              cols="12"
              class="mt-1 text-right"
            >
              <b-button
                type="submit"
                variant="primary"
                :disabled="invalid || !dirty || !selectedClient"
              >
                Create
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BCard,
  BFormGroup, BFormInput, BForm, BButton, BCardTitle,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import flatPickr from 'vue-flatpickr-component'
import useJwt from '@/auth/jwt/useJwt'
import RootClientSelect from '@/views/components/root-client-select/ClientSelect.vue'
import DateTimeForm from '@/views/components/date-time-form/DateTimeForm.vue'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCardTitle,

    // validations
    ValidationProvider,
    ValidationObserver,

    // date time picker
    // flatPickr,

    // company picker
    RootClientSelect,

    // date time
    DateTimeForm,
  },
  data() {
    return {
      resourceName: 'Campaign',
      resourceNamePlural: 'Campaigns',
      adminAccess: false,
      adminSuffix: '', // 'AsRoot' if root user
      userData: JSON.parse(localStorage.getItem('userData')),

      // form data
      selectedCompany: null,
      selectedClient: null,
      campaignData: {
        name: null,
        start_date: null,
        end_date: null,
      },
      required,
    }
  },
  computed: {

  },
  created() {
    if (this.userData.role.id === 1) {
      this.adminAccess = true
      this.adminSuffix = 'AsRoot'
      this.setCompanyId(this.$route.params.companyId)
    }
  },
  methods: {
    createCampaign() {
      if (this.adminAccess) {
        // add company id if root user
        this.campaignData.companyId = this.selectedCompany
      }

      this.$refs.resourceForm.validate().then(success => {
        if (success) {
          // reset form validation state
          // so we can track if form data is dirty
          this.$refs.resourceForm.reset()
          this.campaignData.client_id = this.selectedClient
          useJwt[`createCampaign${this.adminSuffix}`](
            this.campaignData,
          )
            .then(response => {
              const resourceData = response.data.data

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${resourceData.name} Created`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Campaign created successfully.',
                },
              })
              this.$router.push({ name: 'view-campaign', params: { id: resourceData.id } })
            })
            .catch(error => {
              if (error.response.status === 400) {
                // highlights all applicable erroneous inputs mentioned by the server.
                this.$refs.resourceForm.setErrors(error.response.data.error)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: error.response.data.error ? error.response.data.error : 'Error',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: error.response.data.details || '',
                  },
                })
              }
            })
        }
      })
    },
    setCompanyId(companyId) {
      if (companyId) {
        this.selectedCompany = companyId
      }
    },
    setClientId(clientId) {
      if (clientId) {
        this.selectedClient = clientId
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
